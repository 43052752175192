import envVariable from "./envVariable";
import { FormReferential, FormReferentials, UserInfoInterface } from "./storageTypes";


export default class storage {
  static setFolderId(folder_id: string): boolean  {
    localStorage.setItem(envVariable('REACT_APP_FOLDER_ID_STORAGE'), folder_id)
    return true
  }

  static getFolderId(): string|null {
    return localStorage.getItem(envVariable('REACT_APP_FOLDER_ID_STORAGE'))
  }

  static setFormId(form_id: string): boolean  {
    localStorage.setItem(envVariable('REACT_APP_CURRENT_FORM_ID_STORAGE'), form_id)
    return true
  }

  static getFormId(): string|null {
    return localStorage.getItem(envVariable('REACT_APP_CURRENT_FORM_ID_STORAGE'))
  }

  static getClientFormId(): string {
    return localStorage.getItem(envVariable('REACT_APP_CLIENT_FORM_ID_STORAGE')) ?? envVariable('REACT_APP_FORM_ID')
  }

  static getBackOfficeFormId(): string {
    return localStorage.getItem(envVariable('REACT_APP_BACKOFFICE_FORM_ID_STORAGE')) ?? envVariable('REACT_APP_BACKOFFICE_FORM_ID')
  }

  static getDossierState(): string {
    return localStorage.getItem(envVariable('REACT_APP_DOSSIER_STATE')) ?? ''
  }

  static setDossierState(state: string): boolean {
    localStorage.setItem(envVariable('REACT_APP_DOSSIER_STATE'), state)
    return true
  }

  static setReferential(formId: string, referential: FormReferential): boolean {
    const presentRef = localStorage.getItem('referential');
    const newRef = presentRef ? JSON.parse(presentRef) : {};

    console.log(newRef)
    newRef[formId] = referential;
    localStorage.setItem('referential', JSON.stringify(newRef));
    return true;
  }

  static getReferential(formId: string): FormReferential | null {
    const formReferentials = this.getReferentials()
    return formReferentials ? formReferentials[formId] ?? null : null
  }

  static getReferentials(): FormReferentials | null {
    const jsonReferentials = localStorage.getItem('referential')
    return jsonReferentials ? JSON.parse(jsonReferentials) : null
  }

  static getClientId(): number|null {
    const clientId = Number(localStorage.getItem('client_id'))
    return (isNaN(clientId)) ? null : clientId
  }

  static setClientId(clientId: number): boolean {
    localStorage.setItem('client_id', String(clientId))
    return true
  }

  static getAuthorizedClients(): number[]|null {
    const authorizedClients = localStorage.getItem('authorized_clients')
    if (authorizedClients != null) {
      return JSON.parse(authorizedClients)
    }

    return null
  }

  static setAuthorizedClients(clientsId: number[]): boolean {
    localStorage.setItem('authorized_clients', JSON.stringify(clientsId))
    return true
  }

  static getReceipts(elementId: string): boolean {
    const receiptsString = localStorage.getItem('receipts')

    if (receiptsString != null) {
      const receipts = JSON.parse(receiptsString)

      const result = receipts.filter(receipt => receipt.elementId === elementId)
      return result.length > 0
    }

    return false
  }
  static getUserInformation(): UserInfoInterface|null
  {
    const userProfile = localStorage.getItem('profile')
    return userProfile ? JSON.parse(userProfile) : null
  }

  static clearCredentials(): void {
    localStorage.removeItem(envVariable('REACT_APP_LOCAL_STORAGE_TOKEN_NAME'));
    localStorage.removeItem('profile')
  }
}
