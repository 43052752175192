import { createSlice } from '@reduxjs/toolkit'
import ValidateConditionUseCase from '../../domain/Condition/UseCase/ValidateConditionUseCase';
import FormGateway from '../../gateway/Form/FormGateway';
import {isVisibleConditionBlock} from '../../presentation/util/validateCondition';

// Define a type for the slice state
interface blocks {
  all: string|null,
  blocksVisible: string|null
  blocksVisibleLength: number|null
}

// Define the initial state using that type
const initialState: blocks = {
  all: null,
  blocksVisible: null,
  blocksVisibleLength: null
}

export const blocksSlice = createSlice({
  name: 'blocks',
  initialState: initialState,
  reducers: {
    setAllBlocks: (state, action) => {
      const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
      const datas = validateConditionUseCase.formGatewayInterface.getCurrentValueForFormId()
      const blocksVisible = JSON.parse(action.payload).filter((block) => {
        if (block.condition) {
          return isVisibleConditionBlock(block, validateConditionUseCase.executeWithDatas(block.condition, datas))
        }
        return block.visible
      })
      
      return {
        all: action.payload,
        blocksVisible: JSON.stringify(blocksVisible),
        blocksVisibleLength: blocksVisible.length
      }
    },
    updateBlock: (state, action) => {
      if (state.all) {
        const blocks = JSON.parse(state.all)
        const blockToUpdateIndex = blocks.findIndex(block => block.id === action.payload.id)
        const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())

        blocks[blockToUpdateIndex].state = action.payload.state

        const nextBlockVisibleIndex = blocks.findIndex((block, index) => {
          {
            if (index <= blockToUpdateIndex) {
              return false
            }

            if (undefined !== block.condition) {
              const validationState = validateConditionUseCase.execute(block.condition)
              return  isVisibleConditionBlock(block, validationState)
            } else {
              return (block.visible) ?? false;
            }
          }
        })

        if (blocks[nextBlockVisibleIndex]) {
          blocks[nextBlockVisibleIndex].state = action.payload.stateNext
        }


        const blocksVisible = blocks.filter(block => {
          if (undefined !== block.condition) {
            const validationState = validateConditionUseCase.execute(block.condition)
            return  isVisibleConditionBlock(block, validationState)
          } else {
            return (block.visible) ?? false;
          }
        })

        state.all = JSON.stringify(blocks)
        state.blocksVisible = JSON.stringify(blocksVisible)
        state.blocksVisibleLength = blocksVisible.length
      }
    },
    modifyBlock: (state, action) => {
      if (state.all) {
        const blocks = JSON.parse(state.all)
        const modifyBlockIndex = blocks.findIndex(block => block.id === action.payload.id)
        const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
        const blocksModified = blocks.map((block, index) => {
          if (block.id === action.payload.id) {
            block.state = 'progress'
            return block
          }

          block.state = (modifyBlockIndex < index) ? 'waiting' : 'complete'
          return block
        })

        const blocksVisible = blocks.filter(block => {
          if (undefined !== block.condition) {
            const validationState = validateConditionUseCase.execute(block.condition)
            return  isVisibleConditionBlock(block, validationState)
          } else {
            return (block.visible) ?? false;
          }
        })

        state.all = JSON.stringify(blocksModified)
        state.blocksVisible = JSON.stringify(blocksVisible)
        state.blocksVisibleLength = blocksVisible.length
      }
    }
  }
})

export const { setAllBlocks, updateBlock, modifyBlock } = blocksSlice.actions

export default blocksSlice.reducer
