import {FC, useState} from "react";
import Nouislider from "nouislider-react";

import {IFormFieldProps} from "../../IFormFieldProps";
import FieldErrorMessage from "../messages/FieldErrorMessage";

import wNumb from 'wnumb';
import parse from 'html-react-parser';

interface IProps extends IFormFieldProps {
    id?: string
    min?: number,
    max?: number,
    start?: number,
    suffix?: string,
    name: string,
    initialValue?: number
}

const RangeSlider: FC<IProps> = ({
                                     id,
                                     classes,
                                     min,
                                     max,
                                     start,
                                     label,
                                     suffix,
                                     register,
                                     initialValue,
                                     name,
                                     error,
                                     ...rest
                                 }) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (newValue) => {
        const theValue =  newValue[0];
        newValue[0] = theValue.replace(suffix,'');
        setValue(newValue);
    };

    start = (start) ?? 0

    return (
        <div className={`${classes}  ${rest.readOnly && "readonly-element"}`} title={rest.help}>
                <input className={"checkbox-toggle"} name={id} id={name} ref={register} value={value}/>
            <label>{parse(label??'')}</label>
            <Nouislider
                animate={true}
                className={"slider-range--no-pips-label u-mtm noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr"}
                connect={[true, false]}
                format={wNumb({decimals: 0, suffix: suffix})}
                keyboardSupport={true}
                onSlide={handleChange}
                pips={{mode: "positions", values: [12.5, 25, 37.5, 50, 62.5, 75, 87.5], density: 100}}
                range={{min: [min], max: [max]}}
                start={start}
                step={1}
                tooltips={true}
                disabled={rest.readOnly}
            />
            <div className="flex-container between-xs no-compensation u-mts">
                <span>{min}</span>
                <span>{max} +</span>
            </div>
            {error?.message && <FieldErrorMessage message={error.message}/>}
        </div>
    )
};

export default RangeSlider;

