import {FunctionComponent, useEffect, useState} from 'react';
import {IArrayChoiceProps} from './IArrayChoiceProps';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import FormGateway from '../../../../gateway/Form/FormGateway';
import Element from '../../../../domain/Element/Element';
import envVariable from "../../../util/envVariable";
import FieldErrorMessage from '../messages/FieldErrorMessage';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import {updateElementTimestamp} from '../../../../store/element/elements';
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import parse from "html-react-parser";
import ReferentialGateway from '../../../../domain/Referential/ReferentialGateway';
import GetReferential from '../../../../useCase/Referential/GetReferential';

/**
 * Returns a Lines and Columns Table HTML Representation From given Array Values
 * @param options
 * @param id
 * @param defaultValue
 * @param classes
 * @param register
 * @param name
 * @param error
 * @param variant
 * @param rest
 * @constructor
 */
const ArrayChoice: FunctionComponent<IArrayChoiceProps> = (
    {
        id,
        name,
        variant,
        defaultValue,
        options,
        classes,
        register,
        error,
        ...rest
    }
) => {
    interface ICustomerElementName {
        [key: string]: string
    }

    const dispatch = useAppDispatch()

    const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
    const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
    const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
    const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
    const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
    const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

    const [selectedDismembermentRateDurationValue, setSelectedDismembermentRateDurationValue] = useState(defaultValue ?? '');

    useEffect(
        () => {
            const itemSelected: HTMLElement = document.querySelector('.array-choice--item--selected') as HTMLElement
            if (selectedDismembermentRateDurationValue !== '' && itemSelected) {
                setTimeout(
                    function () {
                        itemSelected.click()
                    },
                    500
                )
            }
        },
        [selectedDismembermentRateDurationValue]
    )

    /**
     * Manage Table Column Click Event
     * @param element
     */
    const handleClick = (
        element
    ) => {

        const submitData = async () => {

            const formGateway = new FormGateway()
            const selectedDismembermentRateIndex = element.currentTarget.attributes.getNamedItem('data-key').value
            const selectedDismembermentRateDurationValue = element.currentTarget.attributes.getNamedItem('data-value').value

            const selectedDismembermentRateData = [];
            selectedDismembermentRateData[id] = selectedDismembermentRateDurationValue;
            options?.options[selectedDismembermentRateIndex].options.map((opt, index) => {
                selectedDismembermentRateData[`${id}_${index}`] = opt.value;
            })

            if (variant && variant === "temporary_dismemberment") {

                const temporaryDismembermentDurationElementsIds: ICustomerElementName = {
                    voisin: 'H_2_dureedem',
                    remake: 'duree_dem',
                    altarea: '',
                    sogenial: 'dem_duree',
                    paref: 'deme_duree_viag',
                    'swisslife-am': 'duree_dem',
                    'aestiam': 'duree_dem',
                    'demo': 'duree_dem',
                }

                const refentialGateway = new ReferentialGateway();
                const referential = await new GetReferential(refentialGateway).execute(null, null);
                const customer = envVariable('REACT_APP_CUSTOMER')
                const durationElementId: string = temporaryDismembermentDurationElementsIds[customer] ?? ''
                const durationValue: string = formGateway.getValueForElementId(referential?.elements?.[durationElementId.toString()] ?? '') ?? ''

                if (customer === 'voisin' && '' !== selectedDismembermentRateDurationValue && parseInt(durationValue, 10) !== parseInt(selectedDismembermentRateDurationValue, 10)) {
                    let amountInLettersElementsNames = [
                        "41171942-7972-4d9f-9506-c5329e6449b5",
                        "68f0c2ca-2a0c-4f3f-a19b-4cd30dd814ed"
                    ]
                    switch (customer) {
                        case 'voisin':
                            amountInLettersElementsNames = [
                                "145b9e20-80b6-476b-ad4a-27a03b048abc"
                            ]
                            break
                        default:
                            break
                    }

                    const amountInLettersElements: NodeListOf<HTMLElement>[] = amountInLettersElementsNames.map(
                        (elementName) => {
                            return document.getElementsByName(elementName)
                        }
                    )

                    amountInLettersElements.forEach(
                        (elementNodes: NodeListOf<HTMLElement>) => {
                            if (elementNodes.length > 0) {
                                const amountInLetterHTMLInputElement = elementNodes[0] as HTMLInputElement
                                amountInLetterHTMLInputElement.value = ''
                            }
                        }
                    )
                }
            }

            const saveUseCase = new SaveUseCase(formGateway)
            saveUseCase.execute(selectedDismembermentRateData).then(
                () => {
                    const elementsIdToRender: string[] = getElementsIdToRender(
                        elementsWithCondition,
                        id,
                        elementsWithCalcule,
                        elementsWithReference
                    )
                    elementsIdToRender.map(
                        (elementIdToRender) => {
                            dispatch(updateElementTimestamp({'id': elementIdToRender}))
                        }
                    )
                }
            )

            setSelectedDismembermentRateDurationValue(selectedDismembermentRateDurationValue)
        }
        submitData()
    };

    const readOnlyClassName = rest.readOnly ? 'readonly-element' : ''

    return (
        <div className={classes}>
            <input type="hidden" name={id} id={name} ref={register} value={selectedDismembermentRateDurationValue}/>
            <label htmlFor={id}>{parse(rest.label ?? '')}</label>
            <div className={readOnlyClassName} title={rest.help}>
                <div className="array-choice">
                    <div className="array-choice__header">
                        {
                            options?.header?.map(
                                (title, index) =>
                                    <div className="array-choice--item__heading" key={index}>
                                        {title}
                                    </div>
                            )
                        }
                    </div>
                    <div className="array-choice__options">
                        {
                            options?.options?.map(
                                (option, index) => {
                                    const largeItemClassName = option.label.length > 15 ? 'array-choice--item--large' : ''
                                    const className = option.value === selectedDismembermentRateDurationValue
                                        ? 'array-choice--item array-choice--item--selected'
                                        : 'array-choice--item'
                                    return <div
                                        className={largeItemClassName + ' ' + className}
                                        key={index}
                                        data-key={index}
                                        data-value={option.value}
                                        onClick={handleClick}
                                    >
                                        <div className={largeItemClassName + ' array-choice--item__heading'}>{option.label}</div>
                                        {
                                            option.options.map(
                                                (optionChild, indexOption) =>
                                                    <div className={largeItemClassName + ' array-choice--item__option'} key={indexOption}>
                                                        {optionChild.label}
                                                    </div>
                                            )
                                        }
                                    </div>

                                }
                            )
                        }
                    </div>
                </div>
                {
                    error?.message && <FieldErrorMessage message={error.message}/>
                }
            </div>
        </div>
    )
};

export default ArrayChoice;
