import {FC, useState} from 'react';
import ICheckboxProps from "./ICheckboxProps";
import FieldErrorMessage from "../messages/FieldErrorMessage";
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import Element from '../../../../domain/Element/Element';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import FormGateway from '../../../../gateway/Form/FormGateway';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import {updateElementTimestamp} from '../../../../store/element/elements';


const MagicCheckbox: FC<ICheckboxProps> = ({disabled, classes, error, register, checked, label, id, bold, onChangeState, ...rest}) => {
  const dispatch = useAppDispatch()

  const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
  const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
  const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
  const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
  const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
  const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

  const [state, setState] = useState<boolean|undefined>(checked)

  function handleChange(e) {
    const data = [];
    data[id] = e.target.checked
    setState(e.target.checked)
    if (onChangeState) {
      onChangeState(id, e.target.checked)
    }
    const saveUseCase = new SaveUseCase(new FormGateway())
    saveUseCase.execute(data).then(() => {
      const elementsIdToRender: string[] = getElementsIdToRender(elementsWithCondition, id, elementsWithCalcule, elementsWithReference)
      elementsIdToRender.map(elementIdToRender => dispatch(updateElementTimestamp({'id': elementIdToRender})))
    })
  }

  return (
        <div className={`${classes}  ${rest.readOnly && "readonly-element"}`}>
            <div className="checkbox-toggle__wrapper" title={rest.help}>
                <input className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default"
                       type="checkbox" defaultChecked={checked} {...rest} disabled={disabled} ref={register} name={id} id={id} onChange={handleChange} />
                <label className="checkbox-toggle__button" htmlFor={id}/>
                <label className={`checkbox-toggle__label ${bold && 'checkbox-toggle__label--bold'} ${state && 'checkbox-toggle__label--checked'}`} htmlFor={id}>{label}</label>
            </div>

            {error?.message && <FieldErrorMessage message={error.message}/>}

        </div>
    )
};

export default MagicCheckbox;
