import * as React from "react";
import {FC, InputHTMLAttributes, useState} from "react";
import parse from "html-react-parser";
import {IFormFieldProps} from "../../IFormFieldProps";
import Element from "../../../../domain/Element/Element";
import Input from "../input/Input";
import OrderedSelectItem from "./OrderedSelectItem";
import {IOptionType} from "../select/IOptionType";

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
    control: any
    clearErrors: (id: string | undefined) => void
    childrenElements: (Element | undefined)[] | undefined
    formData: Record<string, unknown> | []
    defaultValue: string | number | undefined
}

const OrderedSelectGroup: FC<IProps> = (
    {
        id,
        childrenElements,
        formData,
        classes,
        label,
        onChange,
        value,
        defaultValue,
        register,
        clearErrors,
        control,
        error,
        required,
        ...rest
    }
) => {

    childrenElements = childrenElements ?? []
    const optionsList = childrenElements[0]?.attributes?.options ?? [];

    const optionSelectedValues:(number|undefined)[]= childrenElements.map(
        (orderedElement) => orderedElement && formData && Number(formData[orderedElement.id])
    );
    const initialOptionList:IOptionType[] = optionsList.filter(
        (option) =>   !(optionSelectedValues.includes(option.value))
    );

    const [availableChildrenOptions, setAvailableChildrenOptions]= useState<IOptionType[]>(optionsList);

    return (
        <>
            <div className={classes + ` group-ordered-select`} title={rest.help}>
                <div className={`group-ordered-select-label`}>
                    <label>{parse(label??'')}</label>
                </div>
                <div className={`group-ordered-select-content`}>
                    {
                        childrenElements.map(
                            (orderElement) => {
                                return (orderElement &&
                                    <OrderedSelectItem
                                        options={availableChildrenOptions}
                                        id={orderElement.id}
                                        name={orderElement.id}
                                        classes={orderElement.cssClasses}
                                        error={error}
                                        control={control}
                                        register={register}
                                        required={orderElement.attributes?.required}
                                        label={orderElement.attributes?.label}
                                        multiple={orderElement.attributes?.multiple}
                                        help={orderElement.attributes?.help}
                                        readOnly={rest.readOnly || orderElement.attributes?.readonly}
                                        optionsList={optionsList}
                                        setAvailableChildrenOptions={setAvailableChildrenOptions}
                                        //defaultValue={(formData && formData[orderElement.id]) ? formData[orderElement.id] : orderElement.attributes?.defaultValue}
                                    />
                                )
                            }
                        )
                    }
                </div>
                <div>
                    <Input
                        classes={`u-hide`}
                        register={register}
                        type={'number'}
                        id={id}
                        name={id}
                        required={required}
                        readOnly={rest.readOnly}
                        clearErrors={clearErrors}
                        defaultValue={defaultValue}
                    />
                </div>

            </div>
        </>
    )
}

export default OrderedSelectGroup