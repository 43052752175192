const envVariable = (variable: string, defaultVariable: string | null = null): string => {
    return (process.env[variable] && process.env[variable] !== 'undefined' ? process.env[variable] : defaultVariable) ?? '';
}

export const isManualRegistrationEnabled = () => {
    return 'true' === envVariable('REACT_APP_ALLOW_MANUAL_REGISTRATION');
}

export const isFolderDuplicationEnabled = () => {
    return 'true' === envVariable('REACT_APP_ALLOW_FOLDER_DUPLICATION');
}

export default envVariable;
