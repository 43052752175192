import React, {FunctionComponent, InputHTMLAttributes, useState} from 'react';

import {IFormFieldProps} from '../../IFormFieldProps';
import ERadioType from '../radios/ERadioType';
import MyUploaderHeader from './MyUploaderHeader';
import MyUploader from './MyUploader';
import RadiosListTypes from '../radios/RadiosListTypes';
import {ITypesType} from './ITypesType';
import Receipt from '../../../../domain/Receipt/Receipt';
import FindAllReceiptsUseCase from '../../../../useCase/Receipt/FindAllReceiptsUseCase';
import LocalStorageReceiptGateway from '../../../../gateway/Receipt/LocalStorageReceiptGateway';
import {FieldError} from 'react-hook-form';
import FieldErrorMessage from '../messages/FieldErrorMessage';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  classes?: string,
  label?: string,
  help?: string,
  options?: ITypesType[],
  id: string,
  blockId: string,
  register: any
  clearErrors: any
  error: FieldError
}

function findTypeIdForElement(elementId: string, receipts: Receipt[]|null): string {
  if (null === receipts) {
    return ''
  }
  const receiptsForElement = receipts.filter(receipt => {
    return receipt.elementId === elementId
  })

  return (receiptsForElement.length > 0) ? receiptsForElement[0].typeId : ''
}

const MyUploaderWithOptions: FunctionComponent<IProps> = ({classes, label, help, options, id, blockId, register, clearErrors, error, ...rest}) => {
  const receipts = new FindAllReceiptsUseCase(new LocalStorageReceiptGateway()).execute()

  const [value, setValue] = useState<string>(findTypeIdForElement(id, receipts))

  const handleChangeRadioList = (newValue) => {
    setValue(newValue)
  };
  const fieldRef = React.useRef<HTMLInputElement>(null);
  if (error && fieldRef.current) {
    fieldRef.current.scrollIntoView();
  }
  return (
    <>
      <MyUploaderHeader classes={classes}
                        label={label}
                        help={help} />
      <span ref={fieldRef}/>
      {(undefined !== options && options?.length === 1 &&
        <div  className={`col-md-12  ${rest.readOnly && "readonly-element"}`}>
                <MyUploader
                  key={options[0].id}
                  blockId={blockId}
                  id={id}
                  typeId={options[0].id}
                  receiptsDefault={receipts}
                  register={register}
                  clearErrors={clearErrors}
                  error={error}
                  icon={options[0].icon} />
        </div>
      )}
      {(undefined !== options && options?.length > 1 &&
        <div  className={`col-md-12  ${rest.readOnly && "readonly-element"}`}>
          <RadiosListTypes options={options}
                           id={id}
                           type={ERadioType.CIRCLE}
                           name={id}
                           defaultValue={value}
                           handleChangeForParent={handleChangeRadioList}
          />
          {(options &&
            options.map((option, index) => (
              (value === option.id &&
                  <MyUploader
                    key={index}
                    blockId={blockId}
                    id={id}
                    typeId={value}
                    receiptsDefault={receipts}
                    presentationTmp={option.presentation}
                    register={register}
                    clearErrors={clearErrors}
                    error={error}
                    icon={option?.icon} />
              )
            ))
          )}

          {'' === value &&
            error?.message && <FieldErrorMessage message={error.message} />
          }
        </div>
      )}
    </>
  )
}

export default MyUploaderWithOptions;



