import ReferentialInterface from '../../domain/Referential/ReferentialInterface';
import ReferentialGatewayInterface from '../../domain/Referential/ReferentialGatewayInterface';
import storage from '../../presentation/util/storage';
import { FormReferential } from '../../presentation/util/storageTypes';

export default class GetReferential {
  private referential: ReferentialGatewayInterface;

  constructor(ReferentialGateway: ReferentialGatewayInterface) {
    this.referential = ReferentialGateway;
  }

  async execute(formId: string | null, blockId: string | null): Promise<FormReferential | null> {
    let formReferential: FormReferential|null = null
    let requestedFormId = formId
    if (!formId) {
      requestedFormId = storage.getFormId()
      const formReferentials = storage.getReferentials()
      if (blockId && formReferentials) {
        for(const formKey in formReferentials) {
          if (formReferentials[formKey]?.blocks?.includes(blockId)) {
            formReferential = formReferentials[formKey]
            requestedFormId = formKey
            break
          }
        }
      } else {
        requestedFormId = storage.getFormId()
        if (!requestedFormId) {
          return new Promise((resolve) => {
            resolve(null);
         });
       }
        formReferential = storage.getReferential(requestedFormId)
      }
      
    } else {
      formReferential = storage.getReferential(formId)
    }

    if (!requestedFormId) {
      return new Promise((resolve) => {
        resolve(formReferential);
     });
    }
    if (formReferential) {
      return new Promise((resolve) => {
        resolve(formReferential);
     });
    }

    const response = await this.referential.getReferential(requestedFormId);
    if (!response) {
      return null
    }

    const nameIdTab = {};
    for (const element of response?.elements ?? []) {
        if (!(element.name in nameIdTab)) {
            nameIdTab[element.name] = element.id;
        }
    }
    const blocksList = response?.blocks?.map(block => {return block.id}) ?? null
    const newFormReferential: FormReferential = {elements: nameIdTab, blocks: blocksList, productId: response?.productId}
    storage.setReferential(requestedFormId, newFormReferential);

    return newFormReferential
  }
}