import React, {FC, FormEvent, InputHTMLAttributes, useEffect, useState} from 'react'
import {IOptionType} from "../select/IOptionType";
import ERadioType from "./ERadioType";
import {IFormFieldProps} from "../../IFormFieldProps";
import RadioItem from "./RadioItem";
import FieldErrorMessage from "../messages/FieldErrorMessage";
import {updateElementTimestamp} from '../../../../store/element/elements';
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import Element from '../../../../domain/Element/Element';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import FormGateway from '../../../../gateway/Form/FormGateway';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
    options?: IOptionType[],
    id: string,
    defaultValue?: string,
    onChange?: (e: FormEvent<HTMLInputElement>) => void,
    handleChangeForParent?: (newValue: string) => void,
    type: ERadioType,
    name: string
}

const RadiosList: FC<IProps> = ({options, id,  label, defaultValue, type, register, name, classes, error, handleChangeForParent, ...rest}) => {
    const dispatch = useAppDispatch()

    const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
    const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
    const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
    const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
    const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
    const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

    const radioRef = React.useRef<HTMLDivElement>(null);

    const [value, setValue] = useState<string|undefined>(defaultValue);
    const [answerWidth, setAnswerWidth] = useState<number|undefined>(0);

    const handleChange = (newValue: string) => {
        setValue(newValue);

        if (typeof handleChangeForParent !== 'undefined') {
            handleChangeForParent(newValue)
        }

        const data:Array<string> = [];
        data[id] = newValue;
        const saveUseCase = new SaveUseCase(new FormGateway())
        saveUseCase.execute(data).then(() => {
            const elementsIdToRender: string[] = getElementsIdToRender(elementsWithCondition, id, elementsWithCalcule, elementsWithReference)
            elementsIdToRender.map(elementIdToRender => dispatch(updateElementTimestamp({'id': elementIdToRender})))
        })
    };

    useEffect(() => {
        //gestion de la largeur des blocs réponses
        if (radioRef.current != undefined && radioRef.current.parentElement != undefined) {
            const parentWidth = radioRef.current.parentElement.offsetWidth;
            const elementWidth = radioRef.current.offsetWidth;

            if(elementWidth*2 < parentWidth){
                //si l'élement prend moins de la moitié de la largeur du parent, la largeur de la réponse va être égale à celle de l'element
                setAnswerWidth(elementWidth);
            } else {
                //sinon la largeur de la réponse va être la moitié du parent
                setAnswerWidth(parentWidth/2);
            }
        }
    }, []);
    const divStyle = {
        maxWidth: answerWidth,
    };

    return (
        <div className={`${classes}  ${rest.readOnly && "readonly-element"}`} ref={radioRef}>
            {label &&
            <p className={`u-txt-size-l u-txt-color-text-light ${type == ERadioType.CIRCLE ? "u-mbb" : "u-mbs"}`}>{label}</p>}

            <div className={`flex-container`} style={type == ERadioType.RECTANGLE ?  divStyle : {}} title={rest.help}>
                {options?.map(option => {

                    return (
                        <RadioItem option={option} value={value} register={register} name={id} type={type} id={name}
                                   checked={option.value == value} key={option.value}
                                   onChange={handleChange}/>
                    )
                })}

                {error?.message && <FieldErrorMessage message={error.message}/>}
            </div>
        </div>
    )
};
export default RadiosList;
