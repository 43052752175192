import React, { FunctionComponent, useState, useMemo } from 'react'
import parse from 'html-react-parser'

import Button from '../button/Button'
import MarkRounded from '../svg/MarkRounded'
import Block from '../../../domain/Block/Block'
import BlockFormComponent from './BlockFormComponent'
import BlockPreviewComponent from './BlockPreviewComponent'
import { Popover } from 'react-tiny-popover'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import ValidateConditionUseCase from '../../../domain/Condition/UseCase/ValidateConditionUseCase'
import FormGateway from '../../../gateway/Form/FormGateway'
import { isVisibleConditionBlock } from '../../util/validateCondition'
import { updateBlock } from "../../../store/block/blocks"

type Props = {
  pageId: string
  blockId: string
}

interface CustomComponentProps extends React.ComponentPropsWithoutRef<'div'> {
  onMouseEnter: () => void
  onMouseLeave: () => void
}

const CustomComponent = React.forwardRef<HTMLDivElement, CustomComponentProps>((props, ref) => (
  <div ref={ref} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
    {props.children}
  </div>
))

const BlockComponent: FunctionComponent<Props> = ({ pageId, blockId }) => {
  const blocksString = useAppSelector((state: { blocks: { all: string | null } }) => state.blocks.all)
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [block, setBlock] = useState<Block | null>(null)
  const [validationState, setValidationState] = useState<boolean>(false)
  const storeIsBack = useAppSelector((state: { wizzardStep: { isBack: boolean } }) => state.wizzardStep.isBack)
  const dispatch = useAppDispatch()

  useMemo(() => {
    if (blocksString) {
      const blocks: Block[] = JSON.parse(blocksString)
      const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
      const datas = validateConditionUseCase.formGatewayInterface.getCurrentValueForFormId()
      const blocksVisible = blocks.filter((block) => {
        if (block.condition) {
          return isVisibleConditionBlock(block, validateConditionUseCase.executeWithDatas(block.condition, datas))
        }
        return block.visible
      })

      if (blocksVisible[blocksVisible.length - 1].state === 'complete') {
        blocksVisible[blocksVisible.length - 1].state = 'progress'
      }

      if (storeIsBack) {
        blocksVisible.forEach((b) => {
          if (b.id === blocksVisible[blocksVisible.length - 1].id) {
            dispatch(updateBlock({ id: b.id, state: 'progress', stateNext: 'waiting' }))
          } else {
            dispatch(updateBlock({ id: b.id, state: 'complete', stateNext: 'waiting' }))
          }
        })
      }

      const blockFromStore = blocks.find((block) => block.id === blockId) || null
      setBlock(blockFromStore)

      if (blockFromStore && blockFromStore.condition) {
        setValidationState(validateConditionUseCase.execute(blockFromStore.condition))
      }
    }
  }, [blocksString, blockId, dispatch, storeIsBack])

  const isVisible = block ? isVisibleConditionBlock(block, validationState) : false

  return (
    <>
      {block && (
        <>
          {(['complete', 'validate'].includes(block.state) && isVisible) && (
            <BlockPreviewComponent key={block.id} block={block} state={block.state} pageId={pageId} role="" />
          )}
          {(block.state === 'waiting' && isVisible) && (
            <BlockPreviewComponent key={block.id} pageId={pageId} block={block} state="disable" role="" />
          )}
          {(!['complete', 'waiting'].includes(block.state) && isVisible) && (
            <div className={(block.cssClasses || '') + ` box-elevations box-elevations--base box-elevations--mb u-mxAuto`}>
              <h2 className="help-wrapper u-mbs">
                {block.title}
                {block.help && (
                  <Popover
                    isOpen={isPopoverOpen}
                    positions={['bottom']}
                    align={'end'}
                    padding={10}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    content={<div className="box-helpers">{parse(block.help)}</div>}
                  >
                    <CustomComponent onMouseEnter={() => setIsPopoverOpen(true)} onMouseLeave={() => setIsPopoverOpen(false)}>
                      <Button classes="help__button" label="" position="alone" icon={<MarkRounded />} />
                    </CustomComponent>
                  </Popover>
                )}
              </h2>
              <BlockFormComponent pageId={pageId} block={block} />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default BlockComponent
