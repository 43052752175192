import ReferentialGatewayInterface from './ReferentialGatewayInterface';
import ReferentialInterface from './ReferentialInterface';
import envVariable from "../../presentation/util/envVariable";
import Caller from "../Api/Caller";

export default class ReferentialGateway implements ReferentialGatewayInterface {

    async getReferential(formId: string): Promise<ReferentialInterface | null> {
        try {
            return await Caller.executeGet(
                `${envVariable("REACT_APP_API_URL")}/${envVariable("REACT_APP_LOCALE")}/forms/${formId}/mapping`, {}
            ).then(
                data => { return data }
            ).catch(() => { return null })
        } catch (error) {
            console.error('Error fetching Referential data:', error);
            return null;
        }
    }
}